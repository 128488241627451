import React from 'react';
function Footer() {
  return (
    <footer className="container mx-auto py-1 fixed bottom-0 text-center md:left-20 bg-white dark:bg-dark-mode">
      <p className="text-xs text-center text-dark-content dark:text-light-content w-full">
        Copyright © 2024{' '}
        {/* <a
          className="font-medium"
          href=""
          target="_blank"
          rel="noreferrer noopener"
        > */}
        {/* Damien */}
        {/* </a>{' '} */}
        {/* with */}
        {/* <span className="text-gradient font-medium"> Passion</span> & */}
        <span className="text-gradient font-medium"> Damien</span>
        {/* <span className="text-gradient font-medium"> Culture</span> */}
      </p>
    </footer>
  );
}
export default Footer;
